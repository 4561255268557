/* eslint-disable prettier/prettier */
import axios from 'axios';
var Promise = require('promise');
// const baseURL = 'https://www.anprpms.in';
// const baseURL = 'http://127.0.0.1:8000';
const baseURL = process.env.REACT_APP_API_URL;
const axiosAnonymousInstance = axios.create({
    baseURL: baseURL,
    timeout: 120000,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 120000,
    headers: {
        Authorization: localStorage.getItem('access_token') ? 'JWT ' + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        accept: 'application/json'
    }
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (typeof error.response === 'undefined') {
            // alert(
            //     'A server/network error occurred. ' +
            //         'Looks like CORS might be the problem. ' +
            //         'Sorry about this - we will get it fixed shortly.'+error
            // );
            return Promise.reject(error);
        }

        if (error.response.status === 401 && originalRequest.url === baseURL + '/api/token/refresh/') {
            window.location.href = '/login/';
            return Promise.reject(error);
        }

        if (
            error.response.data.code === 'token_not_valid' &&
            error.response.status === 401 &&
            error.response.statusText === 'Unauthorized'
        ) {
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);

                if (tokenParts.exp > now) {
                    console.log("yes");
                    return axiosAnonymousInstance
                        .post('/api/token/refresh/', {
                            refresh: refreshToken
                        })
                        .then((response) => {
                            localStorage.setItem('access_token', response.data.access);
                            // localStorage.setItem('refresh_token', response.data.refresh);

                            axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                            // originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;
                            // return axiosInstance(originalRequest);
                            return axiosInstance({
                                ...originalRequest,
                                headers: {...originalRequest.headers, Authorization: `JWT ${response.data.access}`}
                            })
                        })
                        .catch((err) => {
                            console.log("error:",err);
                            if (err.response.data.code === 'token_not_valid' &&
                                err.response.status === 401){
                                    localStorage.removeItem('access_token');
                                    localStorage.removeItem('refresh_token');
                                    window.location.href = '/login/';
                                    return Promise.reject(err);
                            }
                            console.log("error:",err);
                        });
                } else {
                    console.log('Refresh token is expired', tokenParts.exp, now);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = '/login/';
                }
            } else {
                console.log('Refresh token not available.');
                window.location.href = '/login/';
            }
        }
        if (error.response.status === 400 && error.response.data?.detail === 'account Validity are expire.'){
            window.location.href = '/plan-validity/';
        }
        if (error.response.status === 400 && error.response.data?.detail === "you don't have permission to."){
            window.location.href = '/access-denied/';
        }
        console.log(originalRequest)
        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export { axiosInstance,axiosAnonymousInstance};
// export default axiosAnonymousInstance;
