// assets
import { IconHelp } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
// constant
const icons = { SettingsIcon, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const configuration = {
    id: 'configuration',
    type: 'group',
    children: [
        {
            id: 'configuration',
            title: 'Configuration',
            name: 'configuration',
            type: 'item',
            url: '/configuration',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        }
    ]
};

export default configuration;
