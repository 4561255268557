import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// project imports
// import Customization from '../Customization';
import { axiosInstance } from '../../axios';
import { USER_DETAILS } from 'store/actions';
import { useEffect } from 'react';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const dispatch = useDispatch();
    const handleUserDetail = () => {
        const refreshToken = localStorage.getItem('refresh_token');
        // const access_token = localStorage.getItem('access_token');
        if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
            // exp date in token is expressed in seconds, while now() returns milliseconds:
            const now = Math.ceil(Date.now() / 1000);
            if (tokenParts.exp > now) {
                axiosInstance
                    .get(`account/api/get-user-detail`)
                    .then((res) => {
                        dispatch({
                            type: USER_DETAILS,
                            userDetail: [
                                {
                                    user_detail: res.data.user_detail,
                                    business_detail: res.data.business_detail,
                                    role_detail: res.data.role_detail,
                                    permission: res.data.permission
                                }
                            ]
                        });
                        return true;
                    })
                    .catch((error) => {
                        console.log(error);
                        return false;
                    });
            }
            return false;
        }
        return false;
    };
    useEffect(() => {
        handleUserDetail();
    }, []);
    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
