import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import background from '../../../../assets/images/background.jpg';
import bajajallianz from '../../../../assets/images/bajajallianz.jpg';
import { useState } from 'react';
import { axiosInstance } from '../../../../axios';

const Bajaj = () => {
    // const background = 'src/assets/images/background.jpg';
    // console.log('background', background);
    // const bajajallianz = 'src/assets/images/bajajallianz.jpg';
    // console.log(bajajallianz);

    // const contentStyle = {
    //     flex: 1
    // };
    const [count, setCount] = useState(0);
    React.useEffect(() => {
        function getalerts() {
            axiosInstance.get('detected/dashboard-data').then((res) => {
                setCount(res.data?.four_wheeler_parking?.total_parking_available);
                console.log(res.data);
            });
        }
        getalerts();
        const interval = setInterval(() => getalerts(), 3000);
        return () => clearInterval(interval);
    }, []);
    const footerStyle = {
        background: '#005494',
        color: '#fff',
        textAlign: 'center',
        padding: '5px',
        fontSize: '5rem',
        height: '100px'
    };
    const containerStyle = {
        display: 'grid',
        placeItems: 'center',
        height: '80vh',
        margin: '0'
    };

    const numberStyle = {
        fontSize: '35rem', // Adjust the font size as needed
        fontWeight: 'bold',
        color: '#005494'
    };
    // backgroundImage: `url(${background})`,
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat'
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                minWidth: ' 100vW',
                padding: 0,
                margin: 0
            }}
        >
            <Box sx={{ flexGrow: 0 }}>
                <AppBar position="static" sx={{ bgcolor: '#005494' }}>
                    <Toolbar variant="dense" sx={{ color: '#005494', height: '150px' }}>
                        <img src={bajajallianz} alt="logo" height="120px" width="180px" />
                        <Typography
                            textAlign={'center'}
                            variant="h1"
                            component="div"
                            sx={{ flexGrow: 1, color: 'white', fontSize: '6rem' }}
                        >
                            BAJAJ ALLIANZ HOUSE
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <div>
                <div className="container" style={containerStyle}>
                    <div className="centered-number" style={numberStyle}>
                        {count}
                    </div>
                </div>
                {/* Your main content here */}
            </div>
            <footer style={footerStyle}>
                <Typography textAlign={'center'} variant="h1" component="div" sx={{ flexGrow: 1, color: 'white', fontSize: '5rem' }}>
                    AVAILABLE PARKING SLOTS
                </Typography>
            </footer>
        </div>
    );
};

export default Bajaj;
