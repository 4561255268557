// import { Navigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { USER_DETAILS } from 'store/actions';
// import { axiosInstance } from '../axios';
// import PropTypes from 'prop-types';
// // import { useEffect } from 'react';

// var Promise = require('promise');
// // const getData = async () => {
// //     return await axiosInstance.get(`account/api/get-user-detail`).then((res) => {
// //         res;
// //     });
// // };

// const getData = () =>
//     new Promise((resolve) =>
//         setTimeout(() => {
//             const user = axiosInstance.get(`account/api/get-user-detail`).then((res) => {
//                 res;
//             });
//             resolve(user);
//         }, 5000)
//     );
// // const getData = function () {
// //     return await axiosInstance.get(`account/api/get-user-detail`).then((res) => {
// //         return res;
// //     });
// // };
// export const ProtectedRoute = ({ name, children }) => {
//     const dispatch = useDispatch();
//     const customization = useSelector((state) => state.customization);
//     // const [permission, setPemission] = useState(false);
//     const user = async () => {
//         const refreshToken = localStorage.getItem('refresh_token');
//         // const access_token = localStorage.getItem('access_token');
//         if (refreshToken) {
//             const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
//             // exp date in token is expressed in seconds, while now() returns milliseconds:
//             const now = Math.ceil(Date.now() / 1000);
//             console.log(tokenParts.exp, now, customization);
//             if (tokenParts.exp > now) {
//                 var permissions = false;
//                 if (customization.userDetail.length > 0) {
//                     console.log(customization.userDetail.length);

//                     // name in customization.userDetail[0].permission
//                     //     ? customization.userDetail[0].permission[name].indexOf('view') > -1
//                     //         ? setPemission(true)
//                     //         : setPemission(false)
//                     //     : setPemission(false);
//                     name in customization.userDetail[0].permission
//                         ? customization.userDetail[0].permission[name].indexOf('view') > -1
//                             ? (permissions = true)
//                             : (permissions = false)
//                         : (permissions = false);
//                     console.log('permission1', permissions, name);
//                     return [true, permissions];
//                 } else {
//                     Promise.all([getData]).then((result) => {
//                         console.log('result:', result);
//                         dispatch({
//                             type: USER_DETAILS,
//                             userDetail: [
//                                 {
//                                     user_detail: result.data.user_detail,
//                                     business_detail: result.data.business_detail,
//                                     role_detail: result.data.role_detail,
//                                     permission: result.data.permission
//                                 }
//                             ]
//                         });
//                         name in result.data.permission
//                             ? result.data.permission[name].indexOf('view') > -1
//                                 ? (permissions = true)
//                                 : (permissions = false)
//                             : (permissions = false);
//                         console.log('permission3', result.data.permission);
//                     });
//                     console.log('permission2', permissions, name);
//                     return [true, permissions];
//                     // const user_details = Promise.resolve(getData());
//                     // console.log('getData:', getData());
//                     // user_details.then((result) => {
//                     //     console.log('result:', result);
//                     //     dispatch({
//                     //         type: USER_DETAILS,
//                     //         userDetail: [
//                     //             {
//                     //                 user_detail: result.data.user_detail,
//                     //                 business_detail: result.data.business_detail,
//                     //                 role_detail: result.data.role_detail,
//                     //                 permission: result.data.permission
//                     //             }
//                     //         ]
//                     //     });
//                     //     return true;
//                     // });
//                 }
//             }
//             return [false, false];
//         }
//         return [false, false];
//     };
//     // useEffect(() => {

//     // });
//     const data = user();
//     if (!data[0]) {
//         console.log('Yes', data, data[0]);
//         // user is not authenticated
//         return <Navigate to="/login" />;
//     } else if (!data[1]) {
//         return <Navigate to="/available-parking" />;
//     }

//     // console.log(props);
//     // console.log(children);
//     // console.log(userexist);

//     // } else if (!permission) {
//     //     return <Navigate to="/available-parking" />;
//     // }
//     return children;
// };

// ProtectedRoute.propTypes = {
//     name: PropTypes.any,
//     children: PropTypes.any
// };
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_DETAILS } from 'store/actions';
import { axiosInstance } from '../axios';
import PropTypes from 'prop-types';

var Promise = require('promise');
// const getData = async () => {
//     return await axiosInstance.get(`account/api/get-user-detail`).then((res) => {
//         res;
//     });
// };

const getData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = axiosInstance.get(`account/api/get-user-detail`).then((res) => {
                res;
            });
            resolve(user);
        }, 5000)
    );
// const getData = function () {
//     return await axiosInstance.get(`account/api/get-user-detail`).then((res) => {
//         return res;
//     });
// };
export const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const user = () => {
        const refreshToken = localStorage.getItem('refresh_token');
        // const access_token = localStorage.getItem('access_token');
        if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
            // exp date in token is expressed in seconds, while now() returns milliseconds:
            const now = Math.ceil(Date.now() / 1000);
            console.log(tokenParts.exp, now, customization);
            if (tokenParts.exp > now) {
                if (customization.userDetail.length > 0) {
                    console.log(customization.userDetail.length);
                    return true;
                } else {
                    Promise.all([getData]).then((result) => {
                        console.log('result:', result);
                        dispatch({
                            type: USER_DETAILS,
                            userDetail: [
                                {
                                    user_detail: result.data.user_detail,
                                    business_detail: result.data.business_detail,
                                    role_detail: result.data.role_detail,
                                    permission: result.data.permission
                                }
                            ]
                        });
                    });
                    return true;
                    // const user_details = Promise.resolve(getData());
                    // console.log('getData:', getData());
                    // user_details.then((result) => {
                    //     console.log('result:', result);
                    //     dispatch({
                    //         type: USER_DETAILS,
                    //         userDetail: [
                    //             {
                    //                 user_detail: result.data.user_detail,
                    //                 business_detail: result.data.business_detail,
                    //                 role_detail: result.data.role_detail,
                    //                 permission: result.data.permission
                    //             }
                    //         ]
                    //     });
                    //     return true;
                    // });
                }
            }
            return false;
        }
        return false;
    };

    const userexist = user();
    // console.log(props);
    console.log(children);
    console.log(userexist);
    if (!userexist) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.any
};
