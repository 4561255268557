import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// routing
import ThemeRoutes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
// import Demo from 'views/utilities/demo';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    // const [userdetails, setUserdetails] = useState([]);
    // useLayoutEffect(() => {
    //     axiosInstance
    //         .get(`account/api/get-user-detail`)
    //         .then((res) => {
    //             console.log(res);
    //             dispatch({
    //                 type: USER_DETAILS,
    //                 userDetail: [
    //                     {
    //                         user_detail: res.data.user_detail,
    //                         business_detail: res.data.business_detail,
    //                         role_detail: res.data.role_detail,
    //                         permission: res.data.permission
    //                     }
    //                 ]
    //             });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [userdetails]);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {/* <RoutesDefault /> */}
                    <ThemeRoutes />
                    {/* <BrowserRouter router={ThemeRoutes} /> */}
                    {/* <ThemeRoutes />
                    <RouterProvider router={ThemeRoutes} /> */}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

// const App = () => {
//     return <Demo />;
// };

// export default App;
