// assets
import LocalParkingIcon from '@mui/icons-material/LocalParking';
// constant
const icons = { LocalParkingIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const availableParking = {
    id: 'available-parking',
    type: 'group',
    children: [
        {
            id: 'available-parking',
            title: 'Available Parking',
            name: 'available_parking',
            type: 'item',
            url: '/available-parking',
            icon: icons.LocalParkingIcon,
            breadcrumbs: false
        }
    ]
};

export default availableParking;
