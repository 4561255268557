import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';
import detected from './detected';
import configuration from './configuration';
import parkingdetail from './parkingdetail';
import availableParking from './available-parking';
// import entryAndexit from './entry&exit';
// import generateChallan from './generate-challan';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, availableParking, detected, configuration, parkingdetail] // generateChallan,dashboard, pages, entryAndexit,configuration
};
export default menuItems;
