// assets
import { IconHelp } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BusinessIcon from '@mui/icons-material/Business';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonIcon from '@mui/icons-material/Person';
// constant
const icons = {
    SettingsIcon,
    IconHelp,
    DirectionsCarFilledIcon,
    AspectRatioIcon,
    BusinessIcon,
    ApartmentIcon,
    CorporateFareIcon,
    PersonIcon
};
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const parkingdetail = {
    id: 'parkingdetail',
    type: 'group',
    children: [
        {
            id: 'vehicle-type',
            title: 'Vehicle Type',
            name: 'vehicle_type',
            type: 'item',
            url: '/vehicle-type',
            icon: icons.DirectionsCarFilledIcon,
            breadcrumbs: false
        },
        {
            id: 'parking-size-and-type',
            title: 'Parking Size And Type',
            name: 'vehicle_parking_size_and_type',
            type: 'item',
            url: '/parking-size-and-type',
            icon: icons.AspectRatioIcon,
            breadcrumbs: false
        },
        {
            id: 'business',
            title: 'Business',
            name: 'business',
            type: 'item',
            url: '/business',
            icon: icons.BusinessIcon,
            breadcrumbs: false
        },
        {
            id: 'building',
            title: 'Building',
            name: 'building',
            type: 'item',
            url: '/building',
            icon: icons.ApartmentIcon,
            breadcrumbs: false
        },
        {
            id: 'wing',
            title: 'Wing',
            name: 'wing',
            type: 'item',
            url: '/wing',
            icon: icons.CorporateFareIcon,
            breadcrumbs: false
        },
        {
            id: 'occupant',
            title: 'Occupant',
            name: 'occupant',
            type: 'item',
            url: '/occupant',
            icon: icons.PersonIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'number-parking',
        //     title: 'Number Of Parking',
        //     type: 'item',
        //     url: '/number',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'parking-ByVehicle',
        //     title: 'Parking Type For Wing',
        //     type: 'item',
        //     url: '/ParkingByVehicle',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'parking',
        //     title: 'Parking By Vehicle Type',
        //     type: 'item',
        //     url: '/parking',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'owner',
        //     title: 'Owner Parking Detail',
        //     type: 'item',
        //     url: '/owner',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'parkingowner',
        //     title: 'Parking For Owner',
        //     type: 'item',
        //     url: '/parkingowner',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'ParkingVehicleowner',
        //     title: 'Parking Vehicle For Owner ',
        //     type: 'item',
        //     url: '/ParkingVehicleowner',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'vehiclenumberDetail',
        //     title: 'Vehicle Number Detail ',
        //     type: 'item',
        //     url: '/vehiclenumberDetail',
        //     icon: icons.SettingsIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default parkingdetail;
