import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';
// import { fetchData } from './store/getDefaultActionUser';
// import { USER_DETAILS } from 'store/actions';

//Dispatch the fetchPosts() before our root component renders
// const postsfetch = fetchPosts();
// console.log('postsfetch:', postsfetch);
// const UserDetails = fetchData();
// store.dispatch({ type: USER_DETAILS, userDetail: UserDetails });

const setViewportMetaTag = () => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
    document.head.appendChild(meta);
};

// Call the function to set the viewport meta tag
setViewportMetaTag();

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
