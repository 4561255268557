import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
// import Logos from 'assets/images/icons/JSW-Jaigarh-Port-Ltd_ChiniMandi.jpeg';
import Logos from 'assets/images/icons/ANPRLOGO.png';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isLoading }) => {
    const customization = useSelector((state) => state.customization);

    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            {isLoading == false ? (
                customization.userDetail[0].business_detail.business_logo !== '' ? (
                    <img
                        src={`https://d19r5yohzbn9dr.cloudfront.net/eazzy_park/${customization.userDetail[0].business_detail.business_logo}`}
                        alt={customization.userDetail[0].business_detail.business_name}
                        width="100"
                        height="50"
                    />
                ) : (
                    <img src={Logos} alt="ANPR" width="50" height="50" />
                )
            ) : (
                <Skeleton variant="rectangular" width={50} height={50} animation="wave" />
            )}
            {/* <Logo /> */}
        </ButtonBase>
    );
};
LogoSection.propTypes = {
    isLoading: PropTypes.bool
};
export default LogoSection;
