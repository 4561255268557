import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';
// routes
// import MainRoutes from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { ProtectedRoute } from './ProtectedRoute';
import Bajaj from 'views/pages/authentication/auth-forms/Bajaj';
// import UserDetail from 'globalVariable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// detected routing
const Detected = Loadable(lazy(() => import('views/detection/detected')));
const PartiallyDetected = Loadable(lazy(() => import('views/detection/partially-detected')));

// const Challan = Loadable(lazy(() => import('views/generate-challan/challan')));

// const Entry = Loadable(lazy(() => import('views/entry&exit/Entry')));
// const Exit = Loadable(lazy(() => import('views/entry&exit/Exit')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const AuthForgetPassword = Loadable(lazy(() => import('views/pages/authentication/ForgetPassword')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register')));

// configuration routing
const Configuration = Loadable(lazy(() => import('views/configuration')));

const VehicleType = Loadable(lazy(() => import('views/utilities/VehicleType')));
const ParkingSizeAndType = Loadable(lazy(() => import('views/utilities/ParkingSizeAndType')));
const Business = Loadable(lazy(() => import('views/utilities/Business')));
const Building = Loadable(lazy(() => import('views/utilities/Building')));
const Wing = Loadable(lazy(() => import('views/utilities/Wing')));
const Occupant = Loadable(lazy(() => import('views/utilities/Occupant')));
const PageNotFound = Loadable(lazy(() => import('views/pageNotFound')));
const PlanValidity = Loadable(lazy(() => import('views/planValidity')));
const AvailableParking = Loadable(lazy(() => import('views/availableParking')));
const PagePermission = Loadable(lazy(() => import('views/pagePermission')));
// const NumberParking = Loadable(lazy(() => import('views/utilities/NumberParking')));
// const ParkingType = Loadable(lazy(() => import('views/utilities/ParkingType')));
// const ParkingByVehicleType = Loadable(lazy(() => import('views/utilities/ParkingByVehicleType')));
// const OwnerParkingDetail = Loadable(lazy(() => import('views/utilities/OwnerParkingDetail')));
// const ParkingForOwner = Loadable(lazy(() => import('views/utilities/ParkingForOwner')));
// const ParkingVehicleForOwner = Loadable(lazy(() => import('views/utilities/ParkingVehicleForOwner')));
// const VehicleNumberDetail = Loadable(lazy(() => import('views/utilities/VehicleNumberDetail')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
// const router = createBrowserRouter([MainRoutes]);
// export default router;

// }

const ThemeRoutes = () => (
    <Routes>
        <Route element={<MainLayout />}>
            <Route
                path="/"
                // UserDetails() ? <DashboardDefault /> : <Navigate to="/pages/login/login3" />
                element={
                    <ProtectedRoute>
                        <DashboardDefault />
                    </ProtectedRoute>
                }
            />
            <Route
                path="dashboard/default"
                element={
                    <ProtectedRoute name="dashboard">
                        <DashboardDefault />
                    </ProtectedRoute>
                }
            />
            {/* <Route
                path="/"
                // UserDetails() ? <DashboardDefault /> : <Navigate to="/pages/login/login3" />
                element={
                    <ProtectedRoute>
                        <Detected />
                    </ProtectedRoute>
                }
            /> */}
            <Route
                path="detected/default-detected"
                element={
                    <ProtectedRoute name="detected">
                        <Detected />
                    </ProtectedRoute>
                }
            />
            <Route
                path="detected/partially-detected"
                element={
                    <ProtectedRoute name="partially-detected">
                        <PartiallyDetected />
                    </ProtectedRoute>
                }
            />

            <Route path="vehicle-type" element=<VehicleType /> />

            <Route path="parking-size-and-type" element=<ParkingSizeAndType /> />
            <Route path="business" element=<Business /> />
            <Route path="building" element=<Building /> />
            <Route path="wing" element=<Wing /> />
            <Route path="occupant" element=<Occupant /> />
            <Route path="plan-validity" element=<PlanValidity /> />
            <Route path="access-denied" element=<PagePermission /> />
            <Route path="available-parking" element=<AvailableParking /> />
            {/* <Route path="Number" element=<NumberParking /> />
            <Route path="Parking" element=<ParkingType /> />
            <Route path="ParkingByVehicle" element=<ParkingByVehicleType /> />
            <Route path="Owner" element=<OwnerParkingDetail /> />
            <Route path="Parkingowner" element=<ParkingForOwner /> />
            <Route path="ParkingVehicleowner" element=<ParkingVehicleForOwner /> />
            <Route path="vehiclenumberDetail" element=<VehicleNumberDetail /> /> */}

            {/* <Route
                path="challan"
                element={
                    <ProtectedRoute name="challan">
                        <Challan />
                    </ProtectedRoute>
                }
            /> */}
            {/* <Route
                path="entry"
                element={
                    <ProtectedRoute name="entry">
                        <Entry />
                    </ProtectedRoute>
                }
            />
            <Route
                path="exit"
                element={
                    <ProtectedRoute name="exit">
                        <Exit />
                    </ProtectedRoute>
                }
            /> */}
            <Route
                path="configuration"
                element={
                    <ProtectedRoute name="configuration">
                        <Configuration />
                    </ProtectedRoute>
                }
            />
            {/* <Route
                path="utils/util-typography"
                element={
                    <ProtectedRoute name="detected">
                        <UtilsTypography />
                    </ProtectedRoute>
                }
            />
            <Route path="utils/util-color" element={<UtilsColor />} />
            <Route path="utils/util-shadow" element={<UtilsShadow />} />
            <Route path="icons/tabler-icons" element={<UtilsTablerIcons />} />
            <Route path="icons/material-icons" element={<UtilsMaterialIcons />} /> */}
            {/* <Route path="sample-page" element={<SamplePage />} /> */}
        </Route>
        <Route element={<MinimalLayout />}>
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/register" element={<AuthRegister />} />
            <Route path="/forget-password" element={<AuthForgetPassword />} />
            <Route path="/bajaj" element={<Bajaj />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
    </Routes>
);

export default ThemeRoutes;
