// material-ui
import { Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <>
        <Stack direction="row" justifyContent="center">
            {/* <Typography variant="subtitle2" component={Link} href="https://berrydashboard.io" target="_blank" underline="hover">
        berrydashboard.io
    </Typography> */}
            <Typography variant="subtitle2" underline="hover">
                &copy; powerd by anpr-sipl
            </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center">
            <a target="_blank" href="https://anpr.in" rel="noreferrer">
                anpr.in
            </a>
        </Stack>
        <Stack direction="row" justifyContent="center">
            <a target="_blank" href="https://surajinformatics.com" rel="noreferrer">
                surajinformatics.com
            </a>
        </Stack>
    </>
);

export default AuthFooter;
