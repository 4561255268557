// assets
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// constant
const icons = { HighlightOffIcon, CheckCircleOutlineIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const detected = {
    id: 'detected',
    type: 'group',
    children: [
        {
            id: 'default-detected',
            title: 'Detected',
            name: 'detected',
            type: 'item',
            url: '/detected/default-detected',
            icon: icons.CheckCircleOutlineIcon,
            breadcrumbs: false
        },
        {
            id: 'partially-detected',
            title: 'PartiallyDetected',
            name: 'partially detected',
            type: 'item',
            url: '/detected/partially-detected',
            icon: icons.HighlightOffIcon,
            breadcrumbs: false
        }
    ]
};

export default detected;
